<template>
  <component :is="roleData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="roleData === undefined"
    >
      <h4 class="alert-heading">
        خطأ في جلب بيانات الدّور
      </h4>
      <div class="alert-body">
        لم يتم العثور على مستخدم مع معرف الدّور هذا.راجع
        <b-link
          class="alert-link"
          :to="{ name: 'apps-roles-list' }"
        >
          قائمة الدّور
        </b-link>
        لأدور اخرى.
      </div>
    </b-alert>

    <div>
      <!-- Role Info: Input Fields -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          v-if="roleData"
          ref="form"
          :style="{ height: trHeight }"
          class="repeater-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-alert
                v-model="showMessage"
                dismissible
                :variant="messageType"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="InfoIcon"
                  />
                  <span
                    class="ml-25"
                    v-html="message"
                  />
                </div>
              </b-alert>
            </b-col>
          </b-row>

          <!-- Row Loop -->
          <b-row
            v-for="(item, index) in roleData.users"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <!-- User Name -->
            <b-col md="3">
              <b-form-group
                label="إسم المستخدم"
                :label-for="'item-name-' + index"
              >
                <b-form-input
                  :id="'item-name-' + index"
                  v-model="item.name"
                  :value="item.name"
                  plaintext
                />
              </b-form-group>
            </b-col>

            <!-- Current role -->
            <b-col md="3">
              <b-form-group
                label="الدّور الحالي"
                :label-for="'current-role-' + index"
              >
                <b-form-input
                  :id="'current-role-' + index"
                  :value="roleData.name_arabic"
                  plaintext
                />
              </b-form-group>
            </b-col>

            <!-- New role -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                :vid="`user_role_${index}`"
                name="user_role"
                rules="required"
              >
                <b-form-group
                  label="الدور الجديد"
                  :label-for="'new-role-' + index"
                >
                  <v-select
                    :id="'new-role-' + index"
                    v-model="item.role"
                    dir="rtl"
                    :options="roles"
                    :name="'new-role-' + index"
                    class="w-100"
                    @input="roleChanged(item, index)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Remove Button -->
            <b-col
              lg="2"
              md="3"
              class="mb-50"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2 hidden"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>حدف</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
          <!-- Action Buttons -->
          <b-row>
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                type="submit"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                حفظ التغييرات
              </b-button>
              <b-button
                variant="outline-secondary"
                type="reset"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                إعادة ضبط
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="hidden"
      @click="repeatAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>إضافة مستخدم</span>
    </b-button>
  </component>
</template>

<script>
import {
  BCard, BAlert, BFormInvalidFeedback, BLink, BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import vSelect from 'vue-select';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, alphaNum, email } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import roleStoreModule from '../roleStoreModule';

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BAlert,
    BCard,
    BAlert,
    BLink,
    vSelect,
    // Form Validation
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition, alertMessageMixin],
  data() {
    return {
      required,
    };
  },
  setup() {
    const roleData = ref(null);
    const roles = ref([]);
    const ROLE_APP_STORE_MODULE_NAME = 'app-role';

    // Register module
    if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.unregisterModule(ROLE_APP_STORE_MODULE_NAME);
    });

    const initialRoleData = {};
    store.dispatch('app-role/fetchUsersRole', { id: router.currentRoute.params.id })
      .then((response) => {
        const { data: role, roles: allRoles } = response.data;
        roleData.value = role;
        roles.value = allRoles.map((r) => ({
          label: r.name_arabic,
          value: r.id,
          ...r,
        }));
      }).catch((error) => {
        if (error.response.status === 404) {
          roleData.value = undefined;
        }
      });
    const resetRoleData = () => {
      roleData.value = JSON.parse(JSON.stringify(initialRoleData));
    };
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetRoleData);

    return {
      roleData,
      roles,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  methods: {
    roleChanged(item, index) {
      console.log(item, index);
      this.roleData.users[index].role_id = item.role.value;
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          console.log('this.roleData', this.roleData);
          const data = this.roleData.users.map((user) => ({ user_id: user.id, role_id: user.role_id }));
          console.log('data', data);
          this.$store
            .dispatch('app-role/updateUsersRole', { roleData: data, id: this.roleData.id })
            .then((response) => {
              this.$toast.success(response.data.message);
              router.push('/role').catch(() => {});
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status === 422) {
                  this.$toast.error(err.response.data.message);
                  const { message, errors } = err.response.data;
                  if (errors) {
                    const errorMessage = {
                      message: '',
                      type: 'danger',
                    };
                    Object.values(errors).forEach((error) => {
                      errorMessage.message += `${error[0]}<br>`;
                    });
                    this.displayMessage(errorMessage);
                  }
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      text: message,
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                    },
                  });
                } else {
                  this.$toast.error(err.response.data.error);
                }
              }
              console.log(err);
            });
        }
      });
    },
    repeatAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
